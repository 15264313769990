function formatTimestamp(date, large = false) {
  try {
    let to_date = date.toDate();
    let days_before = this.$moment().diff(to_date, "days");

    if (days_before < 1) return this.$moment(to_date).fromNow();
    else {
      if (large) return this.$moment(to_date).format("ddd DD MMM YYYY hh:mm a");
      else return this.$moment(to_date).format("DD/MM/YYYY hh:mm a");
    }
  } catch (error) {
    return "-";
  }
}

export default formatTimestamp;
