var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "agents" } },
    [
      _vm.getAgent &&
      _vm.getAgentsToArray &&
      _vm.getAgentRoles &&
      _vm.getProjects
        ? _c(
            "div",
            [
              _c(
                "v-card",
                { staticClass: "mb-2 mt-4" },
                [
                  _c(
                    "v-col",
                    { staticClass: "px-4 py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Buscar",
                                  "single-line": "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.agent_projects
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-4", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-data-table",
                            {
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.getAgentsToArray,
                                search: _vm.search,
                                "items-per-page": 30,
                                "sort-by": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.role",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getAgentRoles[item.role]
                                                ? _vm.getAgentRoles[item.role]
                                                    .name
                                                : "Sin asignar"
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.projects",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.agent_projects[item.id]
                                                ? _vm.agent_projects[item.id]
                                                : ""
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.operations",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              icon: "",
                                              to: "/agent/" + item.id
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "fas fa-external-link-alt"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                986402679
                              )
                            },
                            [
                              _vm._v(" "),
                              _vm._v(" "),
                              _vm._v(" "),
                              _c("v-alert", {
                                attrs: {
                                  value: true,
                                  color: "error",
                                  icon: "warning"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "no-results",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            'Your search for "' +
                                              _vm._s(_vm.search) +
                                              '" found no results.'
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2478838959
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }