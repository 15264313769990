import Vue from "vue";
import Vuex from "vuex";
import Agent from "@/store/modules/agent";
import Contact from "@/store/modules/contact";
import Timeline from "@/store/modules/timeline";
import Event from "@/store/modules/event";
import Sale from "@/store/modules/sale";
import Zone from "@/store/modules/zone";
import Ticket from "@/store/modules/ticket";
import Tag from "@/store/modules/tag";
import Device from "@/store/modules/device";
import Project from "@/store/modules/project";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Agent,
    Contact,
    Timeline,
    Sale,
    Zone,
    Event,
    Ticket,
    Tag,
    Device,
    Project,
  },
});
