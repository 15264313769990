module.exports = {
  app: {
    apiKey: "AIzaSyAYrvhLNAm9aaz8wKWclAT4E7RurhohewE",
    authDomain: "morada-2020.firebaseapp.com",
    databaseURL: "https://morada-2020.firebaseio.com",
    projectId: "morada-2020",
    storageBucket: "morada-2020.appspot.com",
    messagingSenderId: "765216660701",
    appId: "1:765216660701:web:9a1898a19e56146a010f34"
  },
  tokens: ["R7+fjte0Av1+MtxoKVfMhkO22GRUSSBIrCN3xvWTKiHvk8HyPUIRf2S3OfpVBcd/MDmAqJ0Kkr10bQwUqsLWSA=="],
  gtm_id: "GTM-KB9MTST",
  statitics_url: "https://datastudio.google.com/embed/reporting/1V72mu7NfcOAhkMrpZEpAW4fF2cylMlyj/page/DVWQB",
  fcm_key: "BG-A0qjLFYaYRqkr498y4r-yKriy-FuX_94Qm1V1ZlYD5vZ86sGvhOFG2gD3KTCFXMg2dYmTbTjeRYvGTvlRkVE",
  contactStates: [
    { id: "nuevo", name: "Nuevo" },
    { id: "interesado", name: "Interesado" },
    { id: "sin-respuesta", name: "Sin respuesta" },
    {
      id: "desestimado",
      name: "Desestimado",
      reasons: [
        "Precio alto",
        "Falta de presupuesto",
        "Morosidad dificil manejar",
        "Junta de propietarios no desea, por invertir en otro gasto",
        "Invertir en su propio software",
        "Utilizan otro software",
        "Mejoras en el app cuando se compara con la  competencia.",
        "Soporte 24/07",
        "Recaudación multibanco",
        "Acceso a camaras de video vigilancia",
        "EXTRANJERO( Clientes bucan referencia en base cartera de clientes)",
        "Datos inválidos",
        "Otros",
      ]
    },
    { id: "curioso", name: "Curioso" },
    { id: "cliente", name: "Cliente" },
  ],
  contactRoles: ["Persona Natural", "Empresa Administradora", "Administrador Independiente"],
  contactImportData: [
    { header: "Nombres", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Correo del Encargado", key: "agent_id", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  contactExportData: [
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "País", key: "country", width: 20, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Proyecto", key: "project", type: "string", width: 20 },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "¿Aceptó términos y condiciones?", key: "terms", type: "terms", width: 20 },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Fecha de última actividad", key: "last_activity", type: "timestamp", width: 20 },
    { header: "Origen", key: "origin", type: "string", width: 20 },
    { header: "Encargado que registró el contacto", key: "created_uid", type: "agent", width: 20 },
  ],
  contactFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6, md: 4 },
      rules: [
        (v) => 
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) || "Teléfono no válido",
      ],
      show_on_detail: true,
      form_update: true,
    },
    country: {
      type: "select",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "select",
      label: "Ciudad",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 } },
    newsletter: {
      type: "checkbox",
      label: "Desea recibir ofertas y promociones de Morada",
      flex: { xs: 12 },
      class: "pt-5",
    },
    terms: {
      type: "checkbox",
      label: "Aceptó los términos y condiciones de Morada",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  tableContact:  [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Email",
      align: "center",
      sortable: false,
      value: "email",
    },
    {
      text: "País",
      align: "center",
      sortable: false,
      value: "country",
    },
   {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  contactActivityImportData: [
    { header: "Correo electrónico", key: "contact_email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Tipo de actividad", key: "activity_type", width: 20, type: "activity" },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
    { header: "Correo del encargado", key: "agent_id", width: 20, type: "agent" },
  ],
  saleImportData: [
    { header: "Nombres", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Dirección", key: "address", width: 20, outlineLevel: 1, type: "string" },
    { header: "Producto", key: "products", width: 20, type: "product" },
    { header: "Cantidad", key: "quantity", width: 20, type: "product_detail" },
    { header: "Precio", key: "price", width: 20, type: "product_detail" },
    { header: "Pago total", key: "total_paid", width: 20, type: "number" },
    { header: "Método de pago", key: "payment_method", width: 20, type: "string" },
    { header: "Estado del pago", key: "paid_status", width: 20, type: "string" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  saleExportData: [
    { header: "Id", key: "id", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Dirección", key: "address", type: "string", width: 20 },
    { header: "Zona", key: "zone", type: "string", width: 20 },
    { header: "Producto/Cantidad/Precio", key: "products", type: "products", width: 30},
    { header: "Cupón/Valor", key: "coupon", type: "coupon", width: 20},
    { header: "Estado de pago", key: "paid_status", type: "string", width: 20 },
    { header: "Método de pago", key: "payment_method", type: "string", width: 20 },
    { header: "Total de compra", key: "total_paid", type: "number", width: 20 },
    { header: "Fecha de compra", key: "created_at", type: "timestamp", width: 20 },
  ],
  salePaidStatus: [
    { name: "PAGO EXITOSO", type: "success" },
    { name: "PROCESANDO PAGO", type: "procesing" },
    { name: "ERROR CULQI", type: "error" },
    { name: "ERROR VISANET", type: "error" },
  ],
  ticketStates: [
    { id: "nuevo", name: "Nuevo" },
    { id: "interesado", name: "Interesado" },
    { id: "sin-respuesta", name: "Sin respuesta" },
    { id: "desestimado", name: "Desestimado" },
    { id: "curioso", name: "Curioso" },
    { id: "marketing", name: "Marketing" },
    { id: "cliente", name: "Cliente" },
  ],
  tableTicket:  [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "Proyecto",
      align: "center",
      sortable: false,
      value: "project",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  ticketFormSchema: {
    ticket_name: {
      type: "text",
      label: "Nombre del ticket",
      placeholder: "Nombre del ticket",
      flex: { xs: 12 },
      show_on_detail: true,
      form_update: true,
    },
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
      (v) => !!v || "Campo requerido",
      (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      no_edit: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) => 
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) || "Teléfono no válido",
      ],
      show_on_detail: true,
    },
    distance: {
      type: "select",
      label: "Metraje",
      items: [
        "40 a 55 m2",
        "65 a 70 m2",
        "70 a 80 m2",
        "80 a 90 m2",
        "80 a 100 m2",
        "90 a 125 m2",
        "100 a 105 m2",
        "100 a 130 m2",
        "150 a 210 m2",
        "165 a 190 m2",
        "225 a 235 m2",
        "250 a 335 m2",
      ],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    rooms: {
      type: "select",
      label: "Número de dormitorios",
      items: ["1", "2", "3"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    budget: {
      type: "select",
      label: "Presupuesto",
      items: [
        "90000 - 120000",
        "140000 - 170000",
        "170000 - 200000",
        "200000 - 300000",
        "200000 - 220000",
        "280000 - 310000",
        "290000 - 325000",
        "345000 - 420000",
        "440000 - 540000",
        "540000 - 630000",
        "630000 - 670000",
        "690000 - 850000",
      ],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    moving: {
      type: "select",
      label: "¿Cuándo tiene planeado mudarse?",
      items: ["Inmediatamente", "En el plazo de un año", "De 12 a 24 meses", "En más de 2 años"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 4 },
      show_on_detail: true,
      form_update: true,
    },
    use_departament: {
      type: "select",
      label: "¿Uso del departamento?",
      items: ["Primera vivienda", "Segunda vivienda", "Proyecto de inversión"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 4 },
      show_on_detail: true,
      form_update: true,
    },
    know_morada: {
      type: "select",
      label: "¿Escuchó de Morada?",
      items: ["Si", "No"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    project: {
      type: "select",
      label: "Proyecto",
      items: ["tacna", "bustamante", "eucaliptos", "arica"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    how_knows_project: {
      type: "select",
      label: "¿Cómo se enteró del proyecto?",
      items: ["Por un conocido", "Pasé por la zona", "Por una revista", "Por redes sociales", "Por una paleta", "Por un panel", "Por un anuncio"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    medium_contact: {
      type: "select",
      label: "Medio de contacto",
      items: ["Sala de ventas", "Llamada", "Correo", "Consulta", "Web", "Urbania", "A dónde vivir", "Whatsapp", "Facebook", "Instagram", "Evento"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 }, rules: [ (v) => !!v || "Campo requerido" ] },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  ticketExportData: [
    { header: "Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Metraje", key: "distance", type: "string", width: 20 },
    { header: "Número de dormitorios", key: "rooms", width: 20, type: "string" },
    { header: "Presupuesto", key: "budget", width: 20, type: "string" },
    { header: "Tiempo de mudanza", key: "moving", width: 20, type: "string" },
    { header: "Uso del departamento", key: "use_departament", width: 20, type: "string" },
    { header: "Conoce Morada", key: "know_morada", width: 20, type: "string" },
    { header: "Estado", key: "state", type: "string", width: 20 },
    { header: "Etiquetas", key: "tags", type: "tags", width: 20 },
    { header: "Proyecto", key: "project", type: "string", width: 20 },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Última actividad", key: "last_comment", type: "string", width: 20 },
    { header: "Fecha de última actividad", key: "last_activity", type: "timestamp", width: 20 },
    { header: "Origen", key: "origin", type: "string", width: 20 },
    { header: "Encargado que registró el ticket", key: "created_uid", type: "agent", width: 20 },
  ],
  ticketImportData: [
    { header: "Nombre del Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Metraje", key: "distance", type: "string", width: 20 },
    { header: "Número de dormitorios", key: "rooms", width: 20, type: "string" },
    { header: "Presupuesto", key: "budget", width: 20, type: "string" },
    { header: "Tiempo de mudanza", key: "moving", width: 20, type: "string" },
    { header: "Uso del departamento", key: "use_departament", width: 20, type: "string" },
    { header: "Conoce Morada", key: "know_morada", width: 20, type: "string" },
    { header: "Proyecto", key: "project", type: "string", width: 20 },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Correo del Encargado", key: "agent_id", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  ticketActivityImportData: [
    { header: "ID del Ticket", key: "ticket_id", width: 20, outlineLevel: 1, type: "string" },
    { header: "Tipo de actividad", key: "activity_type", width: 20, type: "activity" },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
    { header: "Correo del encargado", key: "agent_id", width: 20, type: "agent" },
  ],
  agentTable:  [
    {
      text: "Encargado",
      align: "left",
      value: "full_name",
    },
    {
      text: "Correo electrónico",
      align: "center",
      value: "email",
    },
    {
      text: "Rol",
      align: "center",
      sortable: false,
      value: "role",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  agentFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
      (v) => !!v || "Campo requerido",
      (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
    },
  },
  theme: {
    // dark: true,
    themes: {
      light: {
          primary: "#653D80",
          secondary: "#c392e5",
          accent: "#c392e5",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00"
      },
      dark: {
          primary: "#653D80",
          secondary: "#c392e5",
          accent: "#c392e5",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00"
      }
    }
  }
};